import { DateTime } from 'luxon';
import { environment } from '../../environments/environment';
import { PaginatedResponse } from '../layouts/common-types';
import { AdditionalTab } from '../types';
import { defaultHeaders } from '../utils';
import { HttpClient } from '@angular/common/http';

const { baseUrl, pageSize } = environment.tenant.api;

export type Season = {
    id: number;
    title: string;
    urlAlias: string;
    billboardPhoto: string | null;
    startDate: DateTime;
    endDate: Date;
    name: string;
    description: string;
};

export type Billboard = {
    id: number;
    title: string;
    urlAlias: string;
    billboardPhoto: string | null;
    startDate: DateTime;
    endDate: DateTime;
    name: string;
    description: string;
};

export type Show = {
    id: number;
    languageId: number;
    name: string;
    photo: string | null;
    productType: number;
    schedulings: Scheduling[];
    shortDescription: string;
    title: string;
    urlAlias: string;
    url: string;
};

export type SingleShow = Show & {
    additionalTabs: AdditionalTab[];
    duration: number;
    linkToBuy: string;
    locations: string;
    longDescription: string;
    partners: string;
    photoGallery?: unknown;
    places: unknown[];
    price: string;
    productTypeId: number;
    schedule: string;
    subscriptions: string;
};

const months: Month[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

const days: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

type Month =
    | 'january'
    | 'february'
    | 'march'
    | 'april'
    | 'may'
    | 'june'
    | 'july'
    | 'august'
    | 'september'
    | 'october'
    | 'november'
    | 'december';

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type SchedulingDateTime = {
    year: number;
    month: Month;
    day: number;
    dayOfWeek: Day;
    hour: string;
    minute: string;
    dateTime: DateTime;
};

export function dateTimeToScheduling(dateTime: DateTime): SchedulingDateTime {
    const { year, month, day, hour, minute } = dateTime.toObject();

    return {
        year,
        month: months[month - 1],
        day,
        dayOfWeek: days[dateTime.weekday - 1],
        hour: `${hour}`.padStart(2, '0'),
        minute: `${minute}`.padEnd(2, '0'),
        dateTime,
    };
}

export type Scheduling = {
    id: number;
    address: string;
    startedAt: SchedulingDateTime;
    locationName: string;
};

function isoToDateTime<T>(json: any): T {
    const spreadable = json as object;
    return {
        ...spreadable,
        startDate: json.startDate ? DateTime.fromISO(json.startDate) : undefined,
        endDate: json.endDate ? DateTime.fromISO(json.endDate) : undefined,
        startedAt: json.startedAt ? DateTime.fromISO(json.startedAt) : undefined,
    } as unknown as T;
}

export async function fetchSeason(
    client: HttpClient,
    urlAlias: string,
    languageId: number,
): Promise<Season> {
    const response = await client
        .get<Season>(`${baseUrl}/public/seasons/${urlAlias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
    return isoToDateTime<Season>(response);
}

export async function fetchBillboards(
    client: HttpClient,
    seasonAlias: string,
    languageId: number,
    page: number = 1,
): Promise<PaginatedResponse<Billboard>> {
    const response = await client
        .get<PaginatedResponse<Billboard>>(
            `${baseUrl}/public/billboards/?seasonAlias=${seasonAlias}&languageId=${languageId}&limit=${pageSize}&page=${page}`,
            // `${baseUrl}/public/billboards/?seasonAlias=${seasonAlias}&languageId=${languageId}&isArchived=false&limit=${pageSize}&page=${page}`,
            { headers: defaultHeaders },
        )
        .toPromise();
    response.data = response.data.map((b) => isoToDateTime<Billboard>(b));
    return response;
}

export async function fetchBillboard(
    client: HttpClient,
    alias: string,
    languageId: number,
): Promise<Billboard> {
    const response = await client
        .get<Billboard>(`${baseUrl}/public/billboards/${alias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
    return isoToDateTime(response);
}

export function transformShow<T extends Show>(show: T): T {
    show.schedulings = show.schedulings.map((s) => ({
        ...s,
        startedAt: dateTimeToScheduling(DateTime.fromISO(s.startedAt as unknown as string)),
    }));
    return isoToDateTime(show);
}

export async function fetchShows(
    client: HttpClient,
    billboardId: number,
    languageId: number,
    page: number = 1,
): Promise<PaginatedResponse<Show>> {
    const response = await client
        .get<PaginatedResponse<Show>>(
            // `${baseUrl}/public/shows/?billboardId=${billboardId}&languageId=${languageId}&limit=100&page=${page}`,
            `${baseUrl}/public/shows/?billboardId=${billboardId}&languageId=${languageId}&isArchived=false&limit=100&page=${page}`,
            { headers: defaultHeaders },
        )
        .toPromise();
    response.data = response.data.map(transformShow);
    return response;
}

export async function fetchShow(
    client: HttpClient,
    alias: string,
    languageId: number,
): Promise<SingleShow> {
    const response = await client
        .get<SingleShow>(`${baseUrl}/public/shows/${alias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
    return transformShow(response);
}

export async function fetchShowsForHome(client: HttpClient, languageId: number): Promise<Show[]> {
    const response = await client
        .get<Show[]>(
             `${baseUrl}/public/shows-for-home-page?languageId=${languageId}&limit=${pageSize}&page=1`,
            //`${baseUrl}/public/shows-for-home-page?languageId=${languageId}&isArchived=false&limit=${pageSize}&page=1`, // TODO: Does not fit with API, don't know why it was created/activated.
            { headers: defaultHeaders },
        )
        .toPromise();
    return response.map(transformShow);
}

