<!-- product section Start-->
<section class="music artist bg-artist {{ productType }}" id="artist" *ngIf='products.length'>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>{{ 'common.' + title | translate }}</h2>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o
                    [options]="carouselOptions"
                    (dragging)="isDragging = $event.dragging"
                >
                    <ng-container *ngFor="let product of products">
                        <ng-template carouselSlide>
                            <a [owlRouterLink]="[getUrlForItem(product)]" [stopLink]="isDragging">
                                <div class="blog-agency" style="height: 300px">
                                    <div class="blog-contain" style="height: 100%">
                                        <div
                                            class="img-container center-content"
                                            style="width: 100%; padding: 0; background-repeat: no-repeat; background-size: cover; height: 100%; border-radius: 5px; background-position: center; background: url(&quot; {{
                                                getImageUrl(product.photo)
                                            }} &quot;)"
                                        ></div>
                                        <div class="text-wrap-gradient"></div>
                                        <div class="center-content">
                                            <h4>
                                                {{ product.name }}
                                            </h4>
                                            <!--app-date-time-bands
                                                *ngIf="product.schedulings"
                                                [schedulings]="product.schedulings"
                                                [showLocation]="true"
                                                customClass="date-time-bands-in-home"
                                            ></app-date-time-bands-->
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-12">
                <div class="view-all-link text-right">
                    <a [routerLink]='["/"+linkToAll]'>
                        {{ 'common.vedi_tutti' | translate }}&nbsp; <i class="fa fa-external-link"></i
                    ></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- product section end-->
