import { environment } from '../environments/environment';
import { RootMenuTypes } from '../environments/types';

export function getRootMenuTypeFromPath(path: string): RootMenuTypes | null {
    const match = /^\/([^\/]*)\/.*$/.exec(path);
    const target = match && match[1] ? match[1] : '';
    for (const [productType, route] of Object.entries(environment.tenant.routes)) {
        if (route === target) {
            return productType as RootMenuTypes;
        }
    }
    return null;
}

export const defaultHeaders = { 'Content-Type': 'application/json' };


/**
 * Decode html codes
 * @param htmlText html codes
 */
// tslint:disable-next-line:align
export function decodeHtmlCodes(htmlText: string): string {
  const symbols = {
    '&amp;': '&',
    '&agrave;': 'à',
    '&aacute;': 'á',
    '&egrave;': 'è',
    '&eacute;': 'é',
    '&igrave;': 'ì',
    '&iacute;': 'í',
    '&ograve;': 'ò',
    '&oacute;': 'ó',
    '&ugrave;': 'ù',
    '&uacute;': 'ú',
    '&Agrave;': 'À',
    '&Aacute;': 'Á',
    '&Egrave;': 'È',
    '&Eacute;': 'É',
    '&Igrave;': 'Ì',
    '&Iacute;': 'Í',
    '&Ograve;': 'Ò',
    '&Oacute;': 'Ó',
    '&Ugrave;': 'Ù',
    '&Uacute;': 'Ú',
  };
  // @ts-ignore
  return htmlText.replace(
    /(&amp;|&agrave;|&aacute;|&egrave;|&eacute;|&igrave;|&iacute;|&ograve;|&oacute;|&ugrave;|&uacute;|&Agrave;|&Aacute;|&Egrave;|&Eacute;|&Igrave;|&Iacute;|&Ograve;|&Oacute;|&Ugrave;|&Uacute;)/g, (match) => {
      // @ts-ignore
      return symbols[match];
    });
}
