<!-- subscribe section start-->
<section class="music subscribe bg-gallery">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>{{ 'newsletter.iscriviti_alla_newsletter' | translate }}</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="subscribe-title">
                    <h6 class="subscribe-main">
                        {{ 'newsletter.rimani_sempre_aggiornato' | translate }}
                    </h6>
                </div>
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input
                                    type="email"
                                    class="form-control"
                                    placeholder="{{ 'newsletter.inserisci_email' | translate }}"
                                    name="email"
                                    [ngModelOptions]="{ standalone: true }"
                                    [(ngModel)]="email"
                                    #ctrl="ngModel"
                                    required
                                />
                                <div class="button-primary">
                                    <button
                                        [disabled]="disabled"
                                        (click)="submit()"
                                        class="btn btn-default btn-gradient text-white"
                                    >
                                        {{ 'common.iscriviti' | translate }}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="doneMessage">
                                <h3>{{ doneMessage | translate }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- subscribe section end-->
