import {Component, Input, OnInit} from '@angular/core';
import { News } from '../../news/news.component';
import { environment } from '../../../../environments/environment';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['../popup.component.scss'],
})
export class NewsPopupComponent implements OnInit{
  @Input()
  news: News | undefined;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {}

  getImageUrl(src: string): string {
    return `${environment.tenant.api.baseUrl}/images/${src}`;
  }

  close(): void {
    this.ngbActiveModal.close();
  }

}
