import { Component, Input } from '@angular/core';

type SectionType = 'workshop' | 'season' | 'billboard' | 'show';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: ['./section-header.component.scss'],
})
export class SectionHeader {
    @Input()
    title = '';

    @Input()
    htmlDescription = '';

    @Input()
    photo: string | undefined;

    @Input()
    sectionType: SectionType = 'workshop';
}
