import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as O from 'fp-ts/Option';
import { LanguageService } from './language.service';
import { environment } from '../../../environments/environment';
import { defaultHeaders } from '../../utils';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
// Menu
export interface Menu {
    createdAt: string;
    id: number;
    languageId: number;
    name: string;
    updatedAt: string;
    url?: string;
    active?: boolean;
    children?: Menu[];
}



// @ts-ignore
@Injectable({
    providedIn: 'root',
})
export class NavService {
    // Array
    items = new BehaviorSubject<Menu[]>([]);

    constructor(
      private languageService: LanguageService,
      private http: HttpClient,
      private translateService: TranslateService) {
        this.languageService.language$.subscribe((nextLanguage) => {
            const language = O.toUndefined(nextLanguage);
            if (language) {
                this.fetchMenu(language.id)
                    .then((nextItems) => this.items.next(nextItems))
                    .catch((reason) => {
                        const errorMessage: string =
                            reason && reason.message ? reason.message : 'unknown';
                        console.error(errorMessage);
                        // router.navigateByUrl(`/error`, { state: { errorMessage } });
                    });
            }
        });
    }
    getNameForRoot(rootName: string): string {
    if (rootName === 'itc teatro') {
      return 'ITC Teatro Home';
    }
    if (rootName === 'laboratori' || rootName === 'workshops') {
      return this.translateService.instant('menu.workshops');
    }
    if (rootName === 'produzioni' || rootName === 'productions') {
      return this.translateService.instant('menu.productions');
    }
    if (rootName === 'progetti' || rootName === 'projects') {
      return this.translateService.instant('menu.projects');
    }
    // ToDo: Remove if not needed
    /*if (rootName === 'argine') {
      return 'Il Teatro dell’Argine';
    }
    if (rootName === 'contatti' || rootName === 'contacts') {
      return 'Contattaci';
    }*/
    return rootName;
  }

    async fetchMenu(language: number): Promise<Menu[]> {
      console.log('fetchMenu with lang\n', language);
      const { baseUrl } = environment.tenant.api;
      const data = await this.http
            .get<Menu[]>(`${baseUrl}/site-menus?languageId=${language}`, {
                headers: defaultHeaders,
            })
            .toPromise();
      data.forEach((firstLevel: Menu) => {
            if (firstLevel.url !== 'argine' && firstLevel.url !== 'contatti') {
                firstLevel.children?.unshift({
                    ...firstLevel,
                    name: this.getNameForRoot(firstLevel.name.toLowerCase()),
                    children: [],
                });
                if (firstLevel.url === 'produzioni' || firstLevel.url === 'productions') {
                    firstLevel.children?.push({
                        ...firstLevel,
                        url: 'produzioni/teatrografia',
                        name: 'Teatrografia',
                        children: [],
                    });
                    firstLevel.children?.push({
                        ...firstLevel,
                        url: 'produzioni/tournee',
                        name: 'Tournée',
                        children: [],
                    });
                }
            } else if (firstLevel.url === 'argine') {
                firstLevel.children?.push({
                    ...firstLevel,
                    url: 'argine/team',
                    name: this.translateService.instant('menu.argine.team'),
                    children: [],
                });
                firstLevel.children?.push({
                    ...firstLevel,
                    url: 'argine/places',
                    name: this.translateService.instant('menu.argine.places'),
                    children: [],
                });
                firstLevel.children?.push({
                    ...firstLevel,
                    url: 'argine/prizes',
                    name: this.translateService.instant('menu.argine.prizes'),
                    children: [],
                });
                firstLevel.children?.push({
                    ...firstLevel,
                    url: 'argine/news',
                    name: this.translateService.instant('menu.argine.news'),
                    children: [],
                });
                firstLevel.children?.push({
                    ...firstLevel,
                    url: 'argine/archive',
                    name: this.translateService.instant('menu.argine.archive'),
                    children: [],
                });
            }
        });

      return data;
    }
}
