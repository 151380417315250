<div class="bg-black">
  <app-nav></app-nav>
  <section class="container" style='text-align: center;'>
    <br>
    <h2>404 Pagina non trovata</h2>
    <h3>La pagina cercata non esiste</h3>
    <h4>{{ url }}</h4><br>
    <h2>Ciao! Il Teatro dell’Argine ha un nuovo sito.<br>
      <a [routerLink]='["/"]' style="font-size: inherit; color: #6495ed;">Clicca qui per visitarlo e trovare ciò che cerchi.</a></h2>
    <br>

    ----

    <br><br>
    <h2>404 Page not found</h2>
    <h3>The page you are looking for doesn't exist</h3>
    <h4>{{ url }}</h4><br>
    <h2>Hello! The Teatro dell'Argine has a new website.<br>
      <a [routerLink]='["/"]'  style="font-size: inherit; color: #6495ed;">Click here to visit it and find what you are looking for.</a></h2>

  </section>
  <app-write-us></app-write-us>
  <app-copyright></app-copyright>
</div>

