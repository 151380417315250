import {PaginatedResponse} from '../layouts/common-types';
import {
    MainCategoryData,
    Production,
    ProductionData,
} from './components/production/production.component';

import {environment} from '../../environments/environment';
import {DateTime} from 'luxon';
import {defaultHeaders} from '../utils';
import {SectionData} from '../workshops/components/workshop/workshop.component';
import {HttpClient} from '@angular/common/http';

export interface ProductionTypeData {
    id: number;
    name: string;
}

export interface FilterOptionsForTeatrografia {
    years: number[];
    categories: ProductionTypeData[];
}

interface ProductionTeatrografiaQueryParams {
    languageId: number;
    page: number;
    pageSizeParam?: number;
    mainCategoriesIds: number[];
    years: number[];
    relevantPeopleId?: number;
}

const months: Month[] = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

const days: Day[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

type Month =
    | 'january'
    | 'february'
    | 'march'
    | 'april'
    | 'may'
    | 'june'
    | 'july'
    | 'august'
    | 'september'
    | 'october'
    | 'november'
    | 'december';

type Day = 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday';

export type SchedulingDateTime = {
    year: number;
    month: Month;
    day: number;
    dayOfWeek: Day;
    hour: string;
    minute: string;
    dateTime: DateTime;
};

export function dateTimeToScheduling(dateTime: DateTime): SchedulingDateTime {
    const {year, month, day, hour, minute} = dateTime.toObject();

    return {
        year,
        month: months[month - 1],
        day,
        dayOfWeek: days[dateTime.weekday - 1],
        hour: `${hour}`.padStart(2, '0'),
        minute: `${minute}`.padEnd(2, '0'),
        dateTime,
    };
}

function isoToDateTime<T>(json: any): T {
    const spreadable = json as object;
    return {
        ...spreadable,
        startDate: json.startDate ? DateTime.fromISO(json.startDate) : undefined,
        endDate: json.endDate ? DateTime.fromISO(json.endDate) : undefined,
        startedAt: json.startedAt ? DateTime.fromISO(json.startedAt) : undefined,
    } as unknown as T;
}

const {baseUrl, pageSize} = environment.tenant.api;

function encodedProductionsQuery({
                                     languageId,
                                     page,
                                     pageSizeParam,
                                     mainCategory,
                                     relevantPeopleId,
                                     archived
                                 }: ProductionsQueryParams): {
    base: string;
} {
    const pageSizeQueryParam = pageSizeParam ? pageSizeParam : environment.tenant.api.pageSize;
    const mainCategoryQuery = mainCategory ? `&categories=${mainCategory}` : '';
    const relevantPeopleQuery = relevantPeopleId ? `&relevantPeopleId=${relevantPeopleId}` : '';
    const isArchived = archived ? `&isArchived=${true}` : `&isArchived=${false}`;

    return {
        base: `?languageId=${languageId}${isArchived}${mainCategoryQuery}&limit=${pageSizeQueryParam}&page=${page}${relevantPeopleQuery}`,
    };
}

// Create the query for teatrografia
function encodedProductionTeatrografiaQuery({
    languageId,
    page,
    pageSizeParam,
    mainCategoriesIds,
    years,
    relevantPeopleId }: ProductionTeatrografiaQueryParams): { base: string } {
    const pageSizeQueryParam = pageSizeParam ? pageSizeParam : environment.tenant.api.pageSize;
    let mainCategoryQuery = '';
    mainCategoriesIds.forEach((catId) => {
        mainCategoryQuery += `&categories=${catId}`;
    });

    let yearQuery = '';
    years.forEach((year) => {
        yearQuery += `&years=${year}`;
    });

    const relevantPeopleQuery = relevantPeopleId ? `&relevantPeopleId=${relevantPeopleId}` : '';

    return {
        base: `?languageId=${languageId}&${mainCategoryQuery}${yearQuery}&limit=${pageSizeQueryParam}&page=${page}${relevantPeopleQuery}`,
    };
}

type ProductionsQueryParams = {
    languageId: number;
    page: number;
    pageSizeParam?: number;
    mainCategory?: number;
    relevantPeopleId?: number;
    archived?: boolean;
};

export function fetchSectionData(
    client: HttpClient,
    {
        section,
        languageId,
    }: {
        section: string;
        languageId: number;
    },
): Promise<SectionData> {
    return client
        .get<SectionData>(`${baseUrl}/public/product-types/${section}?languageId=${languageId}`)
        .toPromise();
}

export function fetchMainCategoryData(
    client: HttpClient,
    {
        urlAlias,
        languageId,
    }: {
        urlAlias: string;
        languageId: number;
    },
): Promise<MainCategoryData> {
    return client
        .get<MainCategoryData>(
            `${baseUrl}/public/product-main-categories/${urlAlias}?languageId=${languageId}`,
        )
        .toPromise();
}

export function transformProduction<T extends Production>(production: T): T {
    production.schedulings = production.schedulings.map((s) => ({
        ...s,
        startedAt: dateTimeToScheduling(DateTime.fromISO(s.startedAt as unknown as string)),
    }));
    return isoToDateTime(production);
}

export async function fetchProductions(
    client: HttpClient,
    params: ProductionsQueryParams,
): Promise<PaginatedResponse<Production>> {
    const query = encodedProductionsQuery(params);
    return client
        .get<PaginatedResponse<Production>>(`${baseUrl}/public/products${query.base}`)
        .toPromise();
}

export function fetchProductionsForTeatrografia(
    client: HttpClient,
    params: ProductionTeatrografiaQueryParams,
): Promise<PaginatedResponse<Production>> {
    const query = encodedProductionTeatrografiaQuery(params);
    return client
        .get<PaginatedResponse<Production>>(`${baseUrl}/public/products${query.base}`)
        .toPromise();
}

export async function fetchProduction(
    client: HttpClient,
    urlAlias: string,
    languageId: number,
): Promise<ProductionData> {
    const response = await client
        .get<ProductionData>(`${baseUrl}/public/products/${urlAlias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
    return transformProduction(response);
}

export async function fetchProductionsForHome(
    client: HttpClient,
    languageId: number,
): Promise<Production[]> {
    const response = await client
        .get<Production[]>(
            `${baseUrl}/public/products-for-home-page?languageId=${languageId}&limit=${pageSize}&page=1`,
            {headers: defaultHeaders},
        )
        .toPromise();
    return response.map(transformProduction);
}

export async function fetchFilteringOptions(
    client: HttpClient,
    languageId: number,
): Promise<FilterOptionsForTeatrografia> {
    return await client
        .get<FilterOptionsForTeatrografia>(
            `${baseUrl}/public/products-filters?languageId=${languageId}`,
            {
                headers: defaultHeaders,
            },
        )
        .toPromise();
}

/**
 * Decode html codes
 * @param htmlText html codes
 */
// tslint:disable-next-line:align
export function decodeHtmlCodes(htmlText: string): string {
    const symbols = {
        '&amp;': '&',
        '&agrave;': 'à',
        '&aacute;': 'á',
        '&egrave;': 'è',
        '&eacute;': 'é',
        '&igrave;': 'ì',
        '&iacute;': 'í',
        '&ograve;': 'ò',
        '&oacute;': 'ó',
        '&ugrave;': 'ù',
        '&uacute;': 'ú',
        '&Agrave;': 'À',
        '&Aacute;': 'Á',
        '&Egrave;': 'È',
        '&Eacute;': 'É',
        '&Igrave;': 'Ì',
        '&Iacute;': 'Í',
        '&Ograve;': 'Ò',
        '&Oacute;': 'Ó',
        '&Ugrave;': 'Ù',
        '&Uacute;': 'Ú',
    };
    // @ts-ignore
    return htmlText.replace(
        /(&amp;|&agrave;|&aacute;|&egrave;|&eacute;|&igrave;|&iacute;|&ograve;|&oacute;|&ugrave;|&uacute;|&Agrave;|&Aacute;|&Egrave;|&Eacute;|&Igrave;|&Iacute;|&Ograve;|&Oacute;|&Ugrave;|&Uacute;)/g, (match) => {
            // @ts-ignore
            return symbols[match];
        });
}
