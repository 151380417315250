<!-- footer section start-->
<div class="copyright copyright-bg">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-sm-6 mb-4 col-lg-4  mb-lg-0  text-center text-sm-left">
                <div class="footer-content">
                    <div class="footer-logo">
                        <img
                            alt=""
                            src="/assets/images/logo/logo-itc-teatro-bologna-60.png"
                            style="height: 60px"
                        />
                        <img
                            alt=""
                            src="/assets/images/logo/logo-teatro-dell-argine-bologna-60.png"
                            style="height: 60px; margin-left: 17px"
                        />
                    </div>
                    <!-- p>
                        TEATRO DELL'ARGINE SOCIETÀ COOPERATIVA SOCIALE | SEDE LEGALE VIA DEI GELSI,
                        17 | 40068 SAN LAZZARO DI SAVENA - BOLOGNA | P.I. 02522171202
                    </p Remove when footer will be definitive-->
                    <div class="footer-social">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/teatrodellargine" target="_blank"><i aria-hidden="true" class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/teatrodellargine/" target="_blank"><i aria-hidden="true" class="fa fa-instagram"></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/teatroargine" target="_blank"><i aria-hidden="true" class="fa fa-twitter"></i></a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/channel/UClz7TYmyMK83wnMI5xVglCw" target="_blank"><i aria-hidden="true" class="fa fa-youtube"></i></a>
                            </li>
                        </ul>
                    </div>
                    <div class="footer-partner">
                        <p class="m-t-0">{{"footer.title.partners" | translate}}</p>
                        <ul>
                            <li>
                              <a href="https://www.comune.sanlazzaro.bo.it/ " target="_blank">
                                <img alt="Comune di San Lazzaro di Savena" src="/assets/images/copyright/logo-comune-san-lazzaro-di-savena.png" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.cittametropolitana.bo.it/portale " target="_blank">
                                <img alt="Città Metropolitana di Bologna" src="/assets/images/copyright/logo-citta-metropolitana-bologna.png" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.regione.emilia-romagna.it/ " target="_blank">
                                <img alt="Regione Emilia Romagna" src="/assets/images/copyright/logo-regione-emilia-romagna.png" />
                              </a>
                            </li>
                            <li>
                              <a href="https://www.beniculturali.it/" target="_blank">
                                <img alt="Ministero Beni e Delle Attività Culturali" src="/assets/images/copyright/logo-ministero-beni-attivita-culturali.png" />
                              </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- div class="col offset-xl-1" Remove when footer will be definitive-->
            <div class="offset-2 col-8 offset-sm-0 col-sm-6 col-lg-2  mb-4 mb-lg-0">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4 class="mb-1 mb-md-3 mb-lg-4">{{"footer.title.menu" | translate}}</h4>
                    </div>
                    <div class="footer-content">
                        <ul class="footer-menu">
                            <li>
                                <a [routerLink]='["/", "itcteatro"]' >{{"footer.menu.shows" | translate}}</a>
                            </li>
                            <li>
                                <a [routerLink]='["/", "laboratori"]' >{{"footer.menu.workshops" | translate}}</a>
                            </li>
                            <li>
                                <a [routerLink]='["/", "produzioni"]' >{{"footer.menu.productions" | translate}}</a>
                            </li>
                            <li>
                                <a [routerLink]='["/", "progetti"]' >{{"footer.menu.projects" | translate}}</a>
                            </li>
                            <li>
                                <a [routerLink]='["/", "argine"]' >{{"footer.menu.theatre" | translate}}</a>
                            </li>
                            <li>
                                <a [routerLink]='["/contatti/pag/contattaci"]' >{{"footer.menu.contacts" | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="offset-2 col-8 offset-sm-0 col-sm-6 col-lg-3  mb-4 mb-lg-0">
              <div class="sub-title">
                <div class="footer-title">
                  <h4 class="mb-1 mb-md-3 mb-lg-4">{{"footer.title.contacts" | translate}}</h4>
                </div>
                <div class="footer-content">
                  <ul class="contact-list">
                    <li>
                      {{ 'footer.contacts.secretarys-office' | translate }}
                      <br />Tel. +39 051 6271604
                      <br />Fax +39 051 6278647
                      <br /><a href="mailto:info@teatrodellargine.org" style="text-transform: lowercase">info@teatrodellargine.org</a>
                    </li>
                    <li>
                      {{ 'footer.contacts.box-office' | translate }} ITC Teatro
                      <br />Tel. +39 051 6270150
                      <br /><a href="mailto:biglietteria@itcteatro.it" style="text-transform: lowercase">biglietteria@itcteatro.it</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="offset-2 col-8 offset-sm-0 col-sm-6 col-lg-3  mb-4 mb-lg-0">
              <div class="sub-title">
                <div class="footer-title">
                  <h4 class="mb-1 mb-md-3 mb-lg-4">{{"footer.title.findUs" | translate}}</h4>
                </div>
                <div class="footer-content">
                  <ul class="contact-list">
                    <li>
                      ITC Teatro e ITC Lab
                      <br />Via Rimembranze, 26
                      <br />San Lazzaro di Savena (BO)
                    </li>
                    <li>
                      ITC Studio
                      <br />Via Vittoria, 1
                      <br />San Lazzaro di Savena (BO)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    </div>

    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="mb-3 col-md-8 mb-md-1">
                    <div class="footer-end">
                        <p>
                          <i class="fa fa-copyright"></i> 2024 - Teatro dell'Argine Società Cooperativa Sociale<br>
                          {{"footer.credits.legal-office" | translate}}

                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="payment-card-bottom">
                        <p>
                            Powerd by:
                            <a href="https://www.profili.eu/" target="_blank">Profili</a>
                        </p>
                        <p>
                          <a href="https://www.iubenda.com/privacy-policy/94700753" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy" target='_blank'>Privacy</a>
                          <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js";
                            tag.parentNode.insertBefore(s,tag);};
                            if(w.addEventListener){w.addEventListener("load", loader, false);}
                            else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                          </script>
                        &nbsp;|&nbsp;
                          <a href="https://www.iubenda.com/privacy-policy/94700753/cookie-policy" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Cookie Policy" target='_blank'>Cookie</a>
                          <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js";
                            tag.parentNode.insertBefore(s,tag);};
                            if(w.addEventListener){w.addEventListener("load", loader, false);}
                            else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                          </script>
                        &nbsp;|&nbsp;
                          <a href="https://www.iubenda.com/termini-e-condizioni/94700753" class="iubenda-white no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Termini e Condizioni" target='_blank'>{{"footer.credits.terms-condition" | translate}}</a>
                          <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js";
                            tag.parentNode.insertBefore(s,tag);};
                            if(w.addEventListener){w.addEventListener("load", loader, false);}
                            else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
                          </script>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- footer section end-->
