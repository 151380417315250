import { Component, Input, OnInit } from '@angular/core';

import { fetchWorkshopsForHome } from '../../workshops/utils';
import * as O from 'fp-ts/Option';
import {LanguageService} from '../../shared/service/language.service';
import { getImageUrl } from '../../../utils';
import { ProductType } from '../../types';
import { environment } from '../../../environments/environment';
import {fetchShowsForHome, Scheduling} from '../../shows/utils';
import { fetchProductionsForHome } from '../../productions/utils';
import { fetchProjectsForHome } from '../../projects/utils';
import { HttpClient } from '@angular/common/http';
import {defaultHeaders} from '../../utils';
import {Season} from '../../shows/shows-level-one.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

type Product = {
    photo: string;
    name: string;
    schedulings?: Scheduling[];
    shortDescription: string;
    tagNames: string[];
    urlAlias: string;
    url?: string;
};

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    @Input() productType: ProductType = 'workshop';

    seasonData: Season | undefined;

    products: Product[] = [];

    isDragging = false;

    title = 'not found';
    linkToAll = '/';

    carouselOptions = {
        items: 3,
        margin: 60,
        nav: false,
        dots: true,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
                margin: 10,
            },
            991: {
                items: 2,
                margin: 10,
            },
            992: {
                items: 3,
                margin: 10,
            },
            1200: {
                margin: 30,
            },
        },
    };

    getImageUrl = getImageUrl;

    constructor(
      private languageService: LanguageService,
      private http: HttpClient,
      private translateService: TranslateService) {
        this.languageService.language$.subscribe((nextLanguage) => {
            const currentLanguage = O.toUndefined(nextLanguage);
            if (currentLanguage !== undefined) {
                this.initialize(currentLanguage.id).catch((e) => {
                    // TODO: 500
                    console.error(e);
                });
            }
        });
    }

    async ngOnInit(): Promise<void> {
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.initialize(event.lang === 'en' ? 1 : 2);
      });
    }

    private async initialize(languageId: number): Promise<void> {
        const { routes } = environment.tenant;
        switch (this.productType) {
            case 'workshop':
                this.title = 'laboratori';
                const workshops = await fetchWorkshopsForHome(this.http, languageId);
                this.products = workshops.map((w) => ({
                    ...w,
                    schedulings: [],
                }));
                this.linkToAll = routes.workshops;
                break;
            case 'season':
                this.seasonData = await this.fetchSeasons(this.http, languageId);
                this.title = 'itcTeatro';
                const shows = await fetchShowsForHome(this.http, languageId);
                this.products = shows.map((s) => ({
                    ...s,
                    tagNames: [],
                    photo: s.photo ?? '',
                }));
                this.linkToAll = this.seasonData?.href;
                break;
            case 'production':
                this.title = 'produzioni';
                const productions = await fetchProductionsForHome(this.http, languageId);
                this.products = productions.map((p) => ({
                    ...p,
                    tagNames: [],
                    photo: p.photo ?? '',
                }));
                this.linkToAll = routes.productions;
                break;
            case 'project':
                this.title = 'progetti';
                const projects = await fetchProjectsForHome(this.http, languageId);
                this.products = projects.map((j) => ({
                    ...j,
                    tagNames: [],
                    photo: j.photo ?? '',
                }));
                this.linkToAll = routes.projects;
                break;
            default:
                throw new TypeError(`invalid product type ${this.productType}`);
        }
    }

    getUrlForItem(product: Product): string {
        switch (this.productType) {
          case 'workshop':
              return this.addDetailsLevel(product.url) || '404';
            case 'season':
              return product.url || '404';
            case 'production':
              return this.addDetailsLevel(product.url) || '404';
            case 'project':
              return this.addDetailsLevel(product.url) || '404';
            default:
                return '500';
        }
    }

    addDetailsLevel(url: string | undefined): string | undefined {
      if (!url) {
        return undefined;
      }
      const urlArray = url.split('/');
      const productIdentifier = urlArray[urlArray.length - 1];
      urlArray[urlArray.length - 1] = 'details';
      urlArray.push(productIdentifier);
      console.log(urlArray.join('/'));
      return urlArray.join('/');
    }


  async fetchSeasons(client: HttpClient, languageId: number): Promise<Season> {
    const { baseUrl } = environment.tenant.api;
    const response = await client
      .get<{ data: Season[] }>(
        `${baseUrl}/public/seasons?languageId=${languageId}&isArchived=false&limit=10&page=1`,
        {
          headers: defaultHeaders,
        },
      )
      .toPromise();
    const seasonData = response.data[0] as Season;
    return {
      name: seasonData.name,
      urlAlias: seasonData.urlAlias,
      // TODO: the base URL should be injected from the parent app
      href: `/itcteatro/stg/${seasonData.urlAlias}`,
    };
  }

}
