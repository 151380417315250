import { Component, Input } from '@angular/core';
import { RootMenuTypes } from '../../../environments/types';
import { Location } from '@angular/common';
import { Menu, NavService } from '../../shared/service/nav.service';
import { Router } from '@angular/router';

/**
 * Given a Menu tree and a list of pathnames, return an array of Menu representing
 * the shortest path that matches the input list.
 */
function getMenuTree(input: Menu[], path: string[]): Menu[] {
    const reserved = ['cat', 'pag', 'stg'];
    const result: Menu[] = [];
    let menu = input;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < path.length; i += 1) {
        const url = path[i];
        if (reserved.includes(url)) {
            continue;
        }
        const next = menu.find((item) => {
            // item.url comes in absolute full form
            const parts = item.url?.split('/') ?? [];
            return parts[parts.length - 1] === url;
        });
        if (next === undefined) {
            return result;
        }
        result.push(next);
        menu = next.children || [];
    }
    return result;
}

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
    @Input()
    title: string = '';

    @Input()
    path: string[] = [];

    @Input()
    rootMenuType: RootMenuTypes = 'workshops';

    breadcrumbs: Menu[] = [];

    constructor(
        private location: Location,
        private navService: NavService,
        private router: Router,
    ) {
        // TODO: listen for route changes
        // detect mainCategory for non-terminal items (ie: itcteatro)
        // TODO: fetch polyfill
        navService.items.subscribe((menu) => {
            this.breadcrumbs = getMenuTree(
                menu,
                decodeURI(this.location.path()).replace(/^\//, '').split('/'),
            );
        });
    }
}
