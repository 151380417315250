import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '../common-types';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../shared/service/language.service';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { HttpClient } from '@angular/common/http';

type Sponsor = {
    id: number;
    languageId: number;
    title: string;
    photo: string;
    url: string;
    linkTarget: string;
};

async function fetchSponsors(
    client: HttpClient,
    languageId: number,
): Promise<PaginatedResponse<Sponsor>> {
    const { baseUrl } = environment.tenant.api;
    const query = `?languageId=${languageId}&limit=10&page=1`;
    return client.get<PaginatedResponse<Sponsor>>(`${baseUrl}/public/sponsors${query}`).toPromise();
}

@Component({
    selector: 'app-sponsor',
    templateUrl: './sponsor.component.html',
    styleUrls: ['./sponsor.component.scss'],
})
export class SponsorComponent implements OnInit {
    sponsor: Sponsor[] = [];

    constructor(
        private modalService: NgbModal,
        private languageService: LanguageService,
        private http: HttpClient,
    ) {
        languageService.language$.subscribe((nextLanguage) => {
            const languageId: number = pipe(
                nextLanguage,
                O.map((_) => _.id),
                O.getOrElse(() => 1),
            );
            this.initialize(languageId);
        });
    }

    private async initialize(currentLanguageId: number): Promise<void> {
        const { data, page } = await fetchSponsors(this.http, currentLanguageId);
        this.sponsor = data;
    }

    getImageUrl(src: string) {
        return `${environment.tenant.api.baseUrl}/images/${src}`;
    }

    ngOnInit() {}
}
