import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { Four04Component } from './shared/components/Four04/four04.component';
import { Five05Component } from './shared/components/Five05/five05.component';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    },
    {
        path: 'laboratori',
        loadChildren: () => import('./workshops/workshops.module').then((m) => m.WorkshopsModule),
    },
    {
        path: 'itcteatro',
        loadChildren: () => import('./shows/shows.module').then((m) => m.ShowsModule),
    },
    {
        path: 'produzioni',
        loadChildren: () =>
            import('./productions/productions.module').then((m) => m.ProductionsModule),
    },
    {
        path: 'progetti',
        loadChildren: () => import('./projects/projects.module').then((m) => m.ProjectsModule),
    },
    {
        path: 'argine',
        loadChildren: () => import('./argine/argine.module').then((m) => m.ArgineModule),
    },
    {
        path: 'contatti',
        loadChildren: () => import('./contacts/contacts.module').then((m) => m.ContactsModule),
    },
    {
        path: 'search',
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
    },
    { path: 'error', component: Five05Component },
    { path: '**', component: Four04Component },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
            initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
