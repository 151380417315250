<div class="bg-black">
  <app-nav></app-nav>
  <section class="container">
    <h2>505 Error</h2>
    <h3>We are experiencing some issues at the moment</h3>
    <h4>{{ url }}</h4>
    <code>{{ errorMessage }}</code>
  </section>
  <app-write-us></app-write-us>
  <app-copyright></app-copyright>
</div>

