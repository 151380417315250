<!-- section-header start-->
<div class="row description-container" [ngClass]="sectionType">
    <div class="col-lg-4 order-lg-1 mb-4 mb-lg-0" *ngIf="photo !== undefined">
        <img [src]="photo" class="photo" />
    </div>
    <div class="order-lg-0" [class.col-lg-8]="photo !== undefined" [class.col-lg-12]="photo === undefined">
        <h4 class="description-title">{{ title }}</h4>
        <div class="static-page-html description text-justify mb-3" [innerHTML]="htmlDescription | safeHtml "></div>
    </div>
</div>
<!-- section-header section end-->
