import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LayoutsRoutingModule } from './layouts-routing.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SWIPER_CONFIG, SwiperConfigInterface, SwiperModule } from 'ngx-swiper-wrapper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { CountToModule } from 'angular-count-to';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgxMasonryModule } from 'ngx-masonry';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPayPalModule } from 'ngx-paypal';
import { Ng5SliderModule } from 'ng5-slider';
import { TranslateModule } from '@ngx-translate/core';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// Custom Layout
import { NavComponent } from './nav/nav.component';
import { TopBannerComponent } from './top-banner/top-banner.component';
import { ProductsComponent } from './products/products.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { NewsComponent } from './news/news.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { WriteUsComponent } from './write-us/write-us.component';

// DEPRECATED music layout
import { MusicAlbumComponent } from './music/music-album/music-album.component';
import { MusicTestimonialComponent } from './music/music-testimonial/music-testimonial.component';
import { MusicGalleryComponent } from './music/music-gallery/music-gallery.component';
import { MusicVideoComponent } from './music/music-video/music-video.component';
import { MusicBookingComponent } from './music/music-booking/music-booking.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { SafeHtmlPipe } from '../pipes/safehtml.pipe';
import { DateToDayOfMonth } from '../pipes/dateToDayOfMonth.pipe';
import { CurricolaComponent } from './curricola/curricola.component';
import { SectionHeader } from './section-header/section-header.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
    declarations: [
        TopBannerComponent,
        MusicAlbumComponent,
        ProductsComponent,
        SponsorComponent,
        MusicGalleryComponent,
        MusicVideoComponent,
        MusicBookingComponent,
        NewsComponent,
        SubscribeComponent,
        MusicTestimonialComponent,
        WriteUsComponent,
        NavComponent,
        StaticPageComponent,
        SafeHtmlPipe,
        DateToDayOfMonth,
        CurricolaComponent,
        SectionHeader,
    ],
    imports: [
        CommonModule,
        LayoutsRoutingModule,
        SwiperModule,
        CarouselModule,
        NgbModule,
        GalleryModule.forRoot(),
        SharedModule,
        CountToModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPayPalModule,
        Ng5SliderModule,
        NgxMasonryModule,
        RecaptchaV3Module,
        TranslateModule.forChild({ extend: true }),
    ],
    exports: [
        CurricolaComponent,
        SponsorComponent,
        WriteUsComponent,
        SubscribeComponent,
        ProductsComponent,
        NewsComponent,
        TopBannerComponent,
        TranslateModule,
        NavComponent,
        StaticPageComponent,
        SafeHtmlPipe,
        DateToDayOfMonth,
        SectionHeader
    ],
    providers: [
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG,
        },
        [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LflU7EcAAAAAILu7QS1N9CstsRhg99PZBUlTPPp' }],
    ],
})
export class LayoutsModule {}
