// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment, isBrowser } from './types';

export const environment: Environment = {
    type: 'testing',
    title: 'web',
    tenant: {
        api: {
            baseUrl: isBrowser()
                ? 'https://gta.binariogp.com/api/v1'
                : 'http://localhost:3000/api/v1',
            // baseUrl: 'https://gta.binariogp.com/api/v1',
            assetsUrl: isBrowser()
                ? 'https://gta.binariogp.com/assets'
                : 'http://localhost:8082/assets',
            imagesUrl: isBrowser()
                ? 'https://gta.binariogp.com/api/v1/images'
                : 'http://localhost:3000/api/v1/images',
            // imagesUrl: 'https://gta.binariogp.com/api/v1/images',
            imagesUrlOg: 'https://gta.binariogp.com/api/v1/images',
          pageSize: 10,
        },
      routes: {
        workshops: 'laboratori',
        shows: 'itcteatro',
        productions: 'produzioni',
        projects: 'progetti',
        argine: 'argine',
        contacts: 'contatti',
        ticketing: 'itcteatro/pag/biglietteria',
        theatre: 'itcteatro/pag/il-teatro',
        history: 'argine/pag/storia',
        team: 'argine/team',
        organization: 'argine/pag/organizzazione',
        places: 'argine/places',
        prizes: 'argine/prizes',
        news: 'argine/news',
        archive: 'argine/archive',
      },
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
