import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule, routes } from './app-routing.module';

import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { SharedModule } from './shared/shared.module';
import { Four04Component } from './shared/components/Four04/four04.component';
import { Five05Component } from './shared/components/Five05/five05.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DefaultInterceptor } from './http.interceptor';
import { environment } from '../environments/environment';
import { isBrowser } from '../environments/types';

/**
 * Provides a default logic to fetch translation bundles.
 * Please note that the default path is `assets/i18n/{ISO 639-1}.json`
 *
 */
function HttpLoaderFactory(http: HttpClient) {
    /* return new TranslateHttpLoader(http, environment.tenant.api.assetsUrl + '/i18n/', '.json'); //Set to absolute because of CORS problems during rendering */
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
    declarations: [AppComponent, Four04Component, Five05Component],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutsModule,
        HttpClientModule,
        CarouselModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'it',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(routes, {
            useHash: false,
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'enabled',
        }),
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ],
    // providers: [{ provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true }],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor() {}
}
