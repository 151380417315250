<!-- write-us section start-->
<footer class="music bg-footer pb-0">
    <div class="container">
        <!-- div class="row">
            <div class="col-sm-6">
                <div class="contact-details text-center">
                    <h4 class="contact-heading gradient-text">Le nostre sedi</h4>
                    <h6 class="contact-sub-text">
                        <strong>Sede operativa</strong><br />via Rimembranze 26, San Lazzaro di
                        Savena (BO)
                    </h6>
                    <h6 class="contact-sub-text">
                        <strong>Sede dei laboratori</strong><br />via Vittoria 1, San Lazzaro di
                        Savena (BO)
                    </h6>
                    <h6 class="contact-sub-text">
                        <strong>Sede legale</strong><br />via dei Gelsi 17, San Lazzaro di Savena
                        (BO)
                    </h6>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="contact-details text-center">
                    <h4 class="contact-heading gradient-text">Contattaci</h4>
                    <h6 class="contact-sub-text">
                        <strong>Tel. Direzione</strong><br />+39 051 6271604
                    </h6>
                    <h6 class="contact-sub-text">
                        <strong>Tel. Biglietteria</strong><br />+39 051 6270150
                    </h6>
                    <h6 class="contact-sub-text">
                        <strong>Email ITC Teatro</strong>:
                        <a href="mailto:info@teatrodellargine.org">info@teatrodellargine.org</a>
                    </h6>
                    <h6 class="contact-sub-text">
                        <strong>Email biglietteria ITC Teatro</strong>:
                        <a href="mailto:biglietteria@itcteatro.it">biglietteria@itcteatro.it</a>
                    </h6>
                </div>
            </div>
        </div -->
        <div class="form form-footer p-t-30">
            <div class="row">
                <div class="col-md-10 offset-md-1">
                  <div class="title title4">
                    <div class="main-title">
                      <h2>{{ 'write-us.scrivici' | translate }}</h2>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="{{ 'write-us.tuonome' | translate }}" [ngModelOptions]="{ standalone: true }"
                               [(ngModel)]="name" name="name"/>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <input type="email" placeholder="{{ 'write-us.tuaemail' | translate }}" [ngModelOptions]="{ standalone: true }"
                               [(ngModel)]="email" name="email" />
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea
                            class="form-control"
                            rows="6"
                            placeholder="{{ 'write-us.tuomessaggio' | translate }}"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="message"
                            name="message"
                        ></textarea>
                    </div>
                </div>
                <div class="booking">
                    <button
                        class="btn btn-default btn-gradient m-0-auto"
                        (click)="submit()"
                        [disabled]="!(email && name && message)"
                    >
                        {{ 'common.invia' | translate }}
                    </button>
                </div>
                <div *ngIf="doneMessage">
                    <h3>{{ doneMessage | translate }}</h3>
                </div>
            </div>
        </div>
    </div>
</footer>
