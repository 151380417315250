<div [className]="theme">
  <a
    class="overlay-sidebar-header"
    [class.overlay-sidebar-header-open]="openSide"
    (click)="closeOverlay()"
  ></a>
  <div class="responsive-btn">
    <a (click)="toggleSidebar()">
      <i class="fa fa-bars text-white" aria-hidden="true"></i>
    </a>
  </div>
  <div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
      <a (click)="toggleSidebar()">
        <h5><i class="fa fa-times"></i></h5>
      </a>
    </div>
    <ul class="main-menu">
      <!-- 1st Level Menu -->
      <li *ngFor="let menuItem of menuItems">
        <!-- Sub -->
        <a href="javascript:void(0)" class="dropdown" (click)="setActive(menuItem.id)">
          {{ menuItem.name }}
        </a>
        <!-- 2nd Level Menu -->
        <ul *ngIf="menuItem.children" [class.opensubmenu]="isActive(menuItem.id)">
          <!-- Simple Menu Start-->
          <li *ngFor="let childrenItem of menuItem.children">
            <!-- Link -->
            <a routerLink="{{ '/' + childrenItem.url }}" routerLinkActive="active">
              {{ childrenItem.name }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <ul class="main-menu" style="margin-left: 1rem">
      <li>
        <a class="dropdown nav-link d-flex align-items-center"  href="javascript:void(0)"  (click)="setActive(10)">
          <img class='langimg selected-flag' [src]='selectedLanguageFlag' alt='flag'/>
          <!-- span class='selected-language d-md-flex d-none'>{{ selectedLanguageText | translate }}</span -->
        </a>
        <ul [class.opensubmenu]="isActive(200)">
          <li>
            <a (click)="changeLanguage('en')">
              <img class='langimg mr-2' src='./assets/images/flags/en.png' alt='flag'/>
              <span class='font-small-3' translate>languages.en</span>
            </a>
          </li>
          <li>
            <a (click)="changeLanguage('it')">
              <img class='langimg mr-2' src='./assets/images/flags/it.png' alt='flag'/>
              <span class='font-small-3' translate>languages.it</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <ul style='margin-left: 1rem;'>
      <li>
        <i aria-hidden="true" style='margin-left: 1rem; cursor: pointer;' class="fa fa-search"
           (click)='searchToggled()'></i>
      </li>
    </ul>

    <!--
    <div class="top-header-right">
        <ul>
            <li class="search">
                <a
                    aria-expanded="false"
                    data-toggle="dropdown"
                    href="https://angular.pixelstrap.com/unice/#"
                    id="dropdownMenuButton"
                    ><i class="fa fa-search"></i
                ></a>
            </li>

            <li class="search">
                <a
                    aria-expanded="false"
                    data-toggle="dropdown"
                    href="https://angular.pixelstrap.com/unice/#"
                    id="dropdownMenuButton"
                    ><i class="fa fa-archive"></i
                ></a>
            </li>
        </ul>
    </div>
    -->
  </div>
</div>
