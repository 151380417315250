import { Component, OnInit } from '@angular/core';
import { AdditionalTab, ProductType } from '../../types';
import { LanguageService } from '../../shared/service/language.service';
import { ActivatedRoute } from '@angular/router';
import * as O from 'fp-ts/Option';
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { RootMenuTypes } from '../../../environments/types';
import { defaultHeaders, getRootMenuTypeFromPath } from '../../utils';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";

type StaticPage = {
    id: number;
    title: string;
    urlAlias: string;
    productTypeId: number;
    name: string;
    content: string;
    additionalTabs: AdditionalTab[];
};

@Component({
    selector: 'app-static-page',
    templateUrl: './static-page.component.html',
    styleUrls: ['./static-page.component.scss', '../page-header/page-header.component.scss'],
})
export class StaticPageComponent implements OnInit {
    page: StaticPage | undefined;
    rootMenuType: RootMenuTypes = 'workshops';
    additionalPagesReadMore: number[] = [];

    // Temporary fix
    isContacts = false;

    constructor(
        private route: ActivatedRoute,
        private languageService: LanguageService,
        private location: Location,
        private http: HttpClient,
        private titleService: Title,
        private translateService: TranslateService,
    ) {
        // Running again the init to update params
        this.route.paramMap.subscribe(() => {
          this.languageService.language$.subscribe(() => {
            const currentLanguageId = O.toUndefined(this.languageService.languageId); // 2;
            const { alias } = this.route.snapshot.params;
            const rootMenuType = getRootMenuTypeFromPath(this.location.path());
            this.isContacts = rootMenuType === ('contacts' || 'contatti');
            console.log(this.location.path(), currentLanguageId, rootMenuType);
            if (currentLanguageId !== undefined && rootMenuType) {
              this.rootMenuType = rootMenuType;
              this.initialize(currentLanguageId, alias).catch((e) => {
                // TODO: 500
                console.error(e);
              });
            }
          });
        });
    }

    async ngOnInit(): Promise<void> {
      this.translateService.onLangChange.subscribe((event) => {
        this.initialize(event.lang === 'it' ? 2 : 1, this.route.snapshot.params.alias);
      });
    }

    private async initialize(currentLanguageId: number, urlAlias: string): Promise<void> {
        const { baseUrl } = environment.tenant.api;
        const page = await this.http
            .get<StaticPage>(
                `${baseUrl}/public/static-pages/${urlAlias}?languageId=${currentLanguageId}`,
                {
                    headers: defaultHeaders,
                },
            )
            .toPromise();
        this.page = page;

        console.log(this.page);

        this.titleService.setTitle(page.title);

        this.page.additionalTabs.forEach(x => this.additionalPagesReadMore.push(1));
    }

    showMoreLessAdditionalTabs(index: number): void{
      this.additionalPagesReadMore[index] = this.additionalPagesReadMore[index] ? 0 : 1 ;
    }

}
