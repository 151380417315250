<!-- static page section start-->

<div class="bg-black">
    <app-nav theme="bright"></app-nav>
    <app-spacer height='75px'></app-spacer>
    <app-page-header
        *ngIf="page && !isContacts"
        title="{{ page.name }}"
        [path]="[page.urlAlias]"
        [rootMenuType]="rootMenuType"
    ></app-page-header>


    <!-- begin Temporary fix for Contatti' static pages -->
    <div class="page-header" [ngClass]="rootMenuType" *ngIf='page && isContacts'>
      <div class="container">
        <div class="content">
          <h2>{{ page.name }}</h2>
          <ul class="breadcrumb-path">
            <li class="path-item">
              <a  [routerLink]="['/contatti/pag/contattaci']">
                <p>{{ "common.contattaci" | translate }}</p>
              </a>
            </li>
            <li class="path-item">
              <a>
                <p class='mr-0'><i class="fa fa-circle" style='font-size: smaller;'></i></p>
              </a>
            </li>
            <li class="path-item">
              <a  [routerLink]="['/contatti/pag/dove-siamo']">
                <p>{{ "footer.title.findUs" | translate }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- end of the fix -->

    <!-- begin static page content-->
    <section class="agency blog blog-sec blog-sidebar">
      <div class="container">
        <div class="row description-container">
          <div class="col-12">
            <div class="static-page-html" *ngIf="page" [innerHTML]="page.content | safeHtml"></div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="page && page.additionalTabs.length > 0">
        <h4 class="tab-details">{{ 'staticPage.additionalTabs.title' | translate }}</h4>
        <ngb-accordion>
          <ngb-panel *ngFor="let tab of page.additionalTabs" title="{{ tab.title }}" cardClass='{{rootMenuType + "-color"}}'>
            <ng-template ngbPanelContent>
              <div class=''>
                <div class="static-page-html" [innerHtml]="tab.content | safeHtml"></div>
                <!---
                     [ngClass]="{'limitTextHeight': this.additionalPagesReadMore[page.additionalTabs.indexOf(tab)]}"></div>
                <h6 id="{{ 'readMore_' + page.additionalTabs.indexOf(tab) }}"  (click)='showMoreLessAdditionalTabs(page.additionalTabs.indexOf(tab))'>
                  {{additionalPagesReadMore[page.additionalTabs.indexOf(tab)] ?  ('common.leggi_piu' | translate) : ('common.leggi_meno' | translate) }}
                </h6> -->
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </section>

    <!-- end static page content-->
    <app-spacer></app-spacer>

    <app-copyright></app-copyright>
</div>

<!-- static page section end-->
