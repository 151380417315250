import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { pipe } from 'fp-ts/pipeable';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';

import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { defaultHeaders } from '../../utils';
import { HttpClient } from '@angular/common/http';

export type Language = {
  id: number;
  code: string;
  name: string;
  flag: string;
  isDefault: true;
};

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  init = true;
  language$ = from(this.getLanguage());

  languageId: O.Option<number> = O.none;
  language: O.Option<Language> = O.none;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private http: HttpClient,
  ) {
    this.language$.subscribe(() => {
      this.translateService.setDefaultLang(this.translateService.getBrowserLang());
    });
  }

  setLanguage(language: number): void {

    this.translateService.use(language === 1 ? 'en' : 'it').subscribe(event => {
      console.log(event);
    });
    this.language$ = from(Promise.resolve(O.some({ id: language } as Language)));
    this.languageId = O.some(language);
  }

  private tryNavigator(): string {
    const fallback = 'it';
    try {
      // @ts-ignore
      const lang = /^[a-z]{2}/.exec(navigator?.language)[0];
      if (lang) {
        return lang;
      }
    } catch {}
    return fallback;
  }

  private async getLanguage(): Promise<O.Option<Language>> {
    if (!this.init) {
      return Promise.resolve(this.language);
    }
    const deviceLocale = this.tryNavigator();
    const { baseUrl } = environment.tenant.api;
    return this.http
      .get<Language[]>(`${baseUrl}/public/languages`, { headers: defaultHeaders })
      .toPromise()
      .then((languages: Language[]) =>
        pipe(
          O.fromNullable(
            languages.find(({ code }) => code === deviceLocale),
            // ({
            //     code: 'it',
            //     enable: true,
            // tslint:disable-next-line:max-line-length
            //     flag: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAVFBMVEX///8AkkbOKzfOKTULmVG128W43cjvwsTRN0PuvsFBqG3219nYWWIAkELNJTLQ6tzQMj7v+PPX7eL+/P378PH33+HB4c9IrXVQsHvxyszba3PaYmsE6zCyAAABGElEQVR4nO3dTQ7BABSF0fovRZWqlv3v06SJoUruBOdbwM07K3hFIUmSJEmSJEmSJEmSJH1SU2c6N5l7Lu21ytSOi91tF6nvMsJiuO8jPYZxcLU8RFquQ8LN6RjptH0JZ4GCwnIxD7QoCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQkJCQm/TPjjv9W725vH9xPru5BwuL95fD+xxzAONnWmc5MBXtprlanNHCRJkiRJkiRJkiRJkqS/6QmnLMpeLHBgigAAAABJRU5ErkJggg==',
            //     id: 2,
            //     isDefault: true,
            //     name: 'Italiano',
            // } as Language),
          ),
          O.getOrElse(() => languages.find(({ isDefault }) => isDefault)),
          E.fromNullable('no language found'),
          E.fold(
            (error: string) => {
              // this.router.navigateByUrl(`/error`, { state: { errorMessage: error } });
              console.error(error);
              return Promise.resolve(O.none);
            },
            (language: Language) => {
              this.languageId = O.some(language.id);
              this.language = O.some(language);
              this.init = false;
              console.log('Setted language');
              return Promise.resolve(O.some(language));
            },
          ),
        ),
      )
      .catch((reason) => {
        const errorMessage: string = reason && reason.message ? reason.message : 'unknown';
        // this.router.navigateByUrl(`/error`, { state: { errorMessage } });
        console.error('language service');
        console.error(errorMessage);
        return O.none;
      });
  }
}

