import {
  AbstractControl, NG_VALIDATORS, Validator, ValidatorFn
} from '@angular/forms';
import {Directive} from '@angular/core';
import {CustomValidators} from './customValidators';

@Directive({
  selector: '[fiscalCode-validator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: FiscalCodeValidatorDirective,
    multi: true
  }]
})
export class FiscalCodeValidatorDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    return CustomValidators.fiscalCode(control);
  }
}
