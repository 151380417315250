<!-- top-banner Start-->
<section class="music top-banner">
    <div class="container">
        <div class="row carousel-container">
            <ngb-carousel #carousel [interval]="5000">
                <ng-template ngbSlide *ngFor="let banner of banners; index as i">
                    <div class="picsum-img-wrapper">
                        <img [src]="banner.photo" alt="{{ banner.title }}" />
                        <div class="col banner-button-area">
                            <a
                                href="{{ banner.url }}"
                                target="{{ banner.linkTarget || '_blank' }}"
                                rel="nofollow noopener noreferrer"
                            >
                                <div class="banner-button" [ngClass]="banner.position">
                                    <span>{{ banner.buttonText }}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </ngb-carousel>
        </div>
    </div>
</section>
<!-- top-banner end-->
