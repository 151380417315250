<!-- Nav Start-->
<header class="music loading-header">
    <div [ngClass]="containerClasses">
        <div class="row">
            <div class="col">
                <nav>
                    <div class="logos-container">
                        <a [routerLink]='["/itcteatro"]' class="d-inline-block">
                            <img
                                src="/assets/images/logo/logo-itc-teatro-bologna-60.png"
                                alt="ITC Teatro"
                                class="img-fluid nav-image"
                            />
                        </a>
                        <a [routerLink]='["/"]' class="d-inline-block">
                            <img
                                src="/assets/images/logo/logo-teatro-dell-argine-bologna-60.png"
                                alt="Teatro dell'Argine"
                                class="img-fluid nav-image"
                            />
                        </a>
                    </div>
                    <app-menu [theme]="theme"> </app-menu>
                </nav>
            </div>
        </div>
    </div>
</header>
<!-- Nav end-->
