<!-- blog section start-->
<section class="music blog bg-album mt-5" >
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>{{ 'common.news' | translate }}</h2>
                    </div>
                    <div class="sub-title">
                        <p>{{ 'common.ultime_notizie_dal_teatro' | translate }}</p>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <owl-carousel-o [options]="blogCarouselOptions">
                    <ng-container *ngFor="let blog of blogs">
                        <ng-template carouselSlide class="item">
                            <div class="blog-agency" >
                                <div class="blog-contain">
                                    <div
                                        class="img-container center-content"
                                        style="height: fit-content" (click)='openNewsPopup(blog)'
                                    >
                                        <!-- div class="center-content"
                                            <a href="{{ blog.urlAlias }}" (click)='openNewsPopup(blog)'-->
                                                <div class="row blog-info" style='cursor: pointer;'>
                                                    <div
                                                        class="col-6"
                                                        style="background-size: cover; background-position: center;
                                                        background-image: url(  {{ getImageUrl( blog.photo || '' ) }} );"
                                                    ></div>
                                                    <div class="col-6 para2">
                                                        <h4 class="blog-text">
                                                            {{ blog.name }}
                                                        </h4>
                                                        <p
                                                            class="static-page-html small-font news-default-color"
                                                            [innerHTML]="blog.description | safeHtml"
                                                        ></p>
                                                        <div class="text-wrap-gradient"></div>
                                                    </div>
                                                </div>
                                            <!--/a>
                                         /div -->
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
            <div class="col-12">
                <div class="view-all-link text-right">
                    <!-- a href="{{ linkToAll }}"  -->
                    <a [routerLink]='["/argine/news"]'> <!-- TODO: connect page dynamically -->
                      {{ 'common.vedi_tutte' | translate }}&nbsp; <i class="fa fa-external-link"></i
                    ></a>
                    <!-- TODO: fuck off -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- blog section end-->
