<!-- curricola section Start-->
<section
  class="music sponsor bg-unset mb-5"
  id="sponsor"
  [ngStyle]="{ 'background-image': 'url(assets/images/music/sponser/bg.jpg)' }"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-2 col-md-3 col-sm-4 col-6" *ngFor="let curriculum of curricola">
        <div class="sponsor-img" (click)="openAuthorPopup({ id: curriculum.id,
        firstName: curriculum.firstName,
        lastName: curriculum.lastName,
        photo: curriculum.photo,
        description: curriculum.description })">
          <div class="text-center">
            <div class="overlay-box">
              <img src="{{ curriculum.photo ?  getImageUrl(curriculum.photo) : '/assets/images/no-photo.jpg' }}" alt="" class="img-fluid" />
            </div>
          </div>
          <div class='team-name'>{{ curriculum.firstName }} {{ curriculum.lastName }}</div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- sponsor section end-->
