<div class="page-header" [ngClass]="rootMenuType">
    <div class="container">
        <div class="content">
            <h2>{{ title }}</h2>
            <ul class="breadcrumb-path">
                <li *ngFor="let item of breadcrumbs" class="path-item">
                    <a  [routerLink]="['/'+item.url]">
                        <p>{{ item.name }} <i class="fa fa-angle-double-right mr-0"></i></p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
