import { PaginatedResponse } from '../layouts/common-types';
import {
    MainCategoryData,
    SectionData,
    Workshop,
    WorkshopData,
} from './components/workshop/workshop.component';
import { environment } from '../../environments/environment';
import { defaultHeaders } from '../utils';
import { HttpClient } from '@angular/common/http';

const { baseUrl, pageSize } = environment.tenant.api;

function generateTagNames(workshop: Workshop): string[] {
    return workshop.tagProductDetails
        .map((tpd) => tpd.name)
        .concat(workshop.tagAgeRanges.map((tar) => tar.name));
}

export async function fetchWorkshop(
    client: HttpClient,
    urlAlias: string,
    languageId: number,
): Promise<WorkshopData> {
    const response = await client
        .get<WorkshopData>(`${baseUrl}/public/workshops/${urlAlias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
    response.tagNames = generateTagNames(response);
    return response;
}

function encodeWorkshopsQuery({
    languageId,
    page,
    pageSizeParam,
    mainCategory,
    tagProductDetails,
    tagAgeRanges,
    archived
}: WorkshopsQueryParams): { tags: string; base: string } {
    const pageSizeQueryParam = pageSizeParam ? pageSizeParam : environment.tenant.api.pageSize;
    const mainCategoryQuery = mainCategory ? `&mainCategoryId=${mainCategory}` : '';
    const tagProductDetailsQuery = (tagProductDetails || []).reduce(
        (acc, id) => `${acc}&tagProductDetails=${id}`,
        '',
    );
    const tagAgeRangesQuery = (tagAgeRanges || []).reduce(
        (acc, id) => `${acc}&tagAgeRanges=${id}`,
        '',
    );
    const isArchived = archived ? `&isArchived=${true}` : `&isArchived=${false}`;
    return {
        // base: `?languageId=${languageId}&limit=${pageSize}&page=${page}${mainCategoryQuery}`,
        base: `?languageId=${languageId}${isArchived}&limit=${pageSizeQueryParam}&page=${page}${mainCategoryQuery}`,
        tags: `${tagAgeRangesQuery}${tagProductDetailsQuery}`,
    };
}

type WorkshopsQueryParams = {
    languageId: number;
    page: number;
    pageSizeParam?: number;
    mainCategory?: number;
    tagProductDetails?: number[];
    tagAgeRanges?: number[];
    archived?: boolean;
};
export async function fetchWorkshops(
    client: HttpClient,
    params: WorkshopsQueryParams,
): Promise<PaginatedResponse<Workshop>> {
    const query = encodeWorkshopsQuery(params);
    const response = await client
        .get<PaginatedResponse<Workshop>>(`${baseUrl}/public/workshops${query.base}${query.tags}`)
        .toPromise();
    response.data = response.data.map((workshop: Workshop) => {
        workshop.tagNames = generateTagNames(workshop);
        return workshop;
    });
    return response;
}

export async function fetchWorkshopsForHome(
    client: HttpClient,
    languageId: number,
): Promise<Workshop[]> {
    const response = await client
        .get<Workshop[]>(
            `${baseUrl}/public/workshops-for-home-page?languageId=${languageId}&limit=${pageSize}&page=1`,
        )
        .toPromise();
    return response.map((workshop: Workshop) => {
        workshop.tagNames = generateTagNames(workshop);
        return workshop;
    });
}

export async function fetchSectionData(
    client: HttpClient,
    {
        section,
        languageId,
    }: {
        section: string;
        languageId: number;
    },
): Promise<SectionData> {
    return client
        .get<SectionData>(`${baseUrl}/public/product-types/${section}?languageId=${languageId}`)
        .toPromise();
}

export async function fetchMainCategoryData(
    client: HttpClient,
    {
        urlAlias,
        languageId,
    }: {
        urlAlias: string;
        languageId: number;
    },
): Promise<MainCategoryData> {
    return client
        .get<MainCategoryData>(
            `${baseUrl}/public/product-main-categories/${urlAlias}?languageId=${languageId}`,
        )
        .toPromise();
}
