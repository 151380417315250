import { Component, Input, OnInit } from '@angular/core';
import { ComponentTheme } from '../common-types';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
    @Input()
    theme: ComponentTheme = 'dark';

    containerClasses = {
        container: true,
        dark: true,
        bright: false,
    };

    constructor() {}

    ngOnInit() {
        this.containerClasses = {
            ...this.containerClasses,
            dark: this.theme === 'dark',
            bright: this.theme === 'bright',
        };
    }
}
