import { Component, Input } from '@angular/core';
import * as O from 'fp-ts/lib/Option';

import { LanguageService } from '../../shared/service/language.service';
import { pipe } from 'fp-ts/function';
import { environment } from '../../../environments/environment';
import { DateTime } from 'luxon';
import { getImageUrl } from '../../../utils';
import { NewsPopupComponent } from '../popups/news-popup/news-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';

export interface News {
    id: number;
    languageId: number;
    title: string;
    urlAlias: string;
    newsDate: DateTime;
    photo?: string;
    name: string;
    description: string;
}

async function fetchNews(client: HttpClient, languageId: number): Promise<News[]> {
    const { baseUrl } = environment.tenant.api;
    const response = await client
        .get<{ data: News[] }>(`${baseUrl}/public/news?languageId=${languageId}&limit=10&page=1`)
        .toPromise();
    return response.data.map((d: any) => ({
        ...d,
        newsDate: DateTime.fromISO(d.newsDate),
    }));
}

async function fetchNewsForHome(client: HttpClient, languageId: number): Promise<News[]> {
    const { baseUrl } = environment.tenant.api;
    const response = await client
        .get<News[]>(
            `${baseUrl}/public/news-for-home-page?languageId=${languageId}&limit=10&page=1`,
        )
        .toPromise();
    return response.map((d: any) => ({
        ...d,
        newsDate: DateTime.fromISO(d.newsDate),
    })) as News[];
}

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss'],
})
export class NewsComponent {
    @Input() position = 1;

    /**
     * Show the news boxes large with more details
     */
    @Input() large = false;

    constructor(
        private languageService: LanguageService,
        private modalService: NgbModal,
        private http: HttpClient,
    ) {
        languageService.language$.subscribe((nextLanguage) => {
            const languageId = pipe(
                nextLanguage,
                O.map((_) => _.id),
                O.toUndefined,
            );
            if (languageId) {
                this.initialize(languageId);
            }
        });
    }

    getImageUrl = getImageUrl;

    blogs: News[] = [];

    blogCarouselOptions = {
        items: 3,
        margin: 60,
        nav: false,
        dots: true,
        autoplay: true,
        slideSpeed: 300,
        paginationSpeed: 400,
        loop: true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
                margin: 10,
            },
            991: {
                items: 2,
                margin: 10,
            },
            992: {
                items: 3,
                margin: 10,
            },
            1200: {
                margin: 30,
            },
        },
    };

    openNewsPopup(news: News): void {
        const modal = this.modalService.open(NewsPopupComponent, {
            ariaLabelledBy: 'modal-basic-title',
        });
        modal.componentInstance.news = news;
    }

    initialize(languageId: number) {
        switch (this.position) {
            case 0:
                fetchNews(this.http, languageId)
                    .then((news) => {
                        this.blogs = news;
                    })
                    .catch((e) => console.error(e));
                break;
            case 1:
                fetchNewsForHome(this.http, languageId)
                    .then((news) => {
                        this.blogs = news;
                    })
                    .catch((e) => console.error(e));
                break;
            default:
                fetchNews(this.http, languageId)
                    .then((news) => {
                        this.blogs = news;
                    })
                    .catch((e) => console.error(e));
                break;
        }
    }
}
