import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'dateToDayOfMonth' })
export class DateToDayOfMonth implements PipeTransform {
  transform(value: string): string {
    return DateTime.fromJSDate(new Date(value)).day.toString();
  }
}
