import { PaginatedResponse } from '../layouts/common-types';
import { MainCategoryData, Project, ProjectData } from './components/project/project.component';

import { environment } from '../../environments/environment';
import { defaultHeaders } from '../utils';
import { SectionData } from '../workshops/components/workshop/workshop.component';
import { HttpClient } from '@angular/common/http';

const { baseUrl, pageSize } = environment.tenant.api;

function encodedProjectsQuery({
    languageId,
    page,
    pageSizeParam,
    mainCategory,
    archived}: ProjectsQueryParams): {
    base: string;
} {
    const pageSizeQuery = pageSizeParam ? pageSizeParam : pageSize;
    const mainCategoryQuery = mainCategory ? `&mainCategoryId=${mainCategory}` : '';
    const isArchived = archived ? `&isArchived=${true}` : `&isArchived=${false}`;

    return {
        base: `?languageId=${languageId}${isArchived}&limit=${pageSizeQuery}&page=${page}${mainCategoryQuery}`,
    };
}

type ProjectsQueryParams = {
    languageId: number;
    page: number;
    pageSizeParam?: number;
    mainCategory?: number;
    archived?: boolean;
};

export function fetchSectionData(
    client: HttpClient,
    {
        section,
        languageId,
    }: {
        section: string;
        languageId: number;
    },
): Promise<SectionData> {
    return client
        .get<SectionData>(`${baseUrl}/public/product-types/${section}?languageId=${languageId}`)
        .toPromise();
}

export async function fetchMainCategoryData(
    client: HttpClient,
    {
        urlAlias,
        languageId,
    }: {
        urlAlias: string;
        languageId: number;
    },
): Promise<MainCategoryData> {
    return client
        .get<MainCategoryData>(`${baseUrl}/public/product-main-categories/${urlAlias}?languageId=${languageId}`)
        .toPromise();
}

export async function fetchProjects(
    client: HttpClient,
    params: ProjectsQueryParams,
): Promise<PaginatedResponse<Project>> {
    const { baseUrl } = environment.tenant.api;
    const query = encodedProjectsQuery(params);
    const response = await client
        .get<PaginatedResponse<Project>>(`${baseUrl}/public/projects${query.base}`)
        .toPromise();
    response.data = response.data.map((project: Project) => {
        return project;
    });
    return response;
}

export async function fetchProject(
    client: HttpClient,
    urlAlias: string,
    languageId: number,
): Promise<ProjectData> {
    return client
        .get<ProjectData>(`${baseUrl}/public/products/${urlAlias}?languageId=${languageId}`, {
            headers: defaultHeaders,
        })
        .toPromise();
}

export async function fetchProjectsForHome(
    client: HttpClient,
    languageId: number,
): Promise<Project[]> {
    return client
        .get<Project[]>(
            `${baseUrl}/public/projects-for-home-page?languageId=${languageId}&limit=${pageSize}&page=1`,
            { headers: defaultHeaders },
        )
        .toPromise();
}



