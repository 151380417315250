import { Component, Input, OnInit } from '@angular/core';
import { Banner } from '../../home/home.component';

@Component({
    selector: 'app-top-banner',
    templateUrl: './top-banner.component.html',
    styleUrls: ['./top-banner.component.scss'],
})
export class TopBannerComponent implements OnInit {
    @Input()
    banners: Banner[] = [];

    sideBarDisplay: string = 'none';

    constructor() {}

    ngOnInit() {}

    sideBar() {
        this.sideBarDisplay === 'none'
            ? (this.sideBarDisplay = 'block')
            : (this.sideBarDisplay = 'none');
    }
}
