import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-505',
  templateUrl: './five05.component.html',
  styleUrls: ['./five05.component.scss'],
})
export class Five05Component implements OnInit {
  url = '';
  errorMessage = 'unknown';

  constructor(private router: Router, private route: ActivatedRoute ) {
  }

  ngOnInit() {
    this.url = this.router.url;
    this.errorMessage = this.route.snapshot.paramMap.get('errorMessage') ?? 'unknown';
  }
}
