import {Component, Input, OnInit} from '@angular/core';

import {Menu, NavService} from '../../../service/nav.service';
import {ComponentTheme} from '../../../../layouts/common-types';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../../service/language.service';
import * as O from 'fp-ts/Option';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @Input()
  theme: ComponentTheme = 'dark';

  public menuItems: Menu[] = [];
  public init = true;
  public openSide = false;
  public activeItem: number | null = null;
  public active = false;
  public activeChildItem: number | null = null;
  public overlay = false;
  public currentLang = '';
  public selectedLanguageText = 'general.languages.it.title';
  public selectedLanguageFlag = './assets/img/flags/it.png';

  constructor(
    public navServices: NavService,
    private router: Router,
    public translateService: TranslateService,
    public languageService: LanguageService) {
    this.navServices.items.subscribe((menuItems) => {
      this.menuItems = menuItems;
    });

    languageService.language$.subscribe((nextLanguage) => {
      const language = O.toUndefined(nextLanguage);
      if (language) {
        this.currentLang = language.id === 1 ? 'en' : 'it';
        this.initialize(this.currentLang);
      }
    });
  }

  initialize(currentLanguage: any): void {
      this.currentLang = currentLanguage;
      this.selectedLanguageText = `languages.${this.currentLang}`;
      this.selectedLanguageFlag = `./assets/images/flags/${this.currentLang}.png`;
  }

  ngOnInit(): void {

    this.translateService.onTranslationChange.subscribe((event: LangChangeEvent) => {
      console.log('Translation changed to: ' + event.lang);
    });
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.navServices.fetchMenu(event.lang === 'en' ? 1 : 2)
        .then((nextItems) => this.navServices.items.next(nextItems));
      this.initialize(event.lang);
    });
  }

  toggleSidebar(): void {
    this.openSide = !this.openSide;
  }

  closeOverlay(): void {
    this.openSide = false;
  }

  // For Active Main menu in Mobile View
  setActive(id: number): void {
    if (this.activeItem === id) {
      this.activeItem = null;
    } else {
      this.activeItem = id;
    }
  }

  isActive(id: number): boolean {
    return this.activeItem === id;
  }

  // For Active Child Menu in Mobile View
  setChildActive(id: number): void {
    if (this.activeChildItem === id) {
      this.activeChildItem = null;
    } else {
      this.activeChildItem = id;
    }
  }

  ischildActive(id: number): boolean {
    return this.activeChildItem === id;
  }

  searchToggled(): void {
    this.router.navigate(['/search/']);
  }

  changeLanguage(language: string): void {

    if (language === 'en') {
      this.languageService.setLanguage(1);
      this.currentLang = 'en';
      this.selectedLanguageText = 'languages.en';
      this.selectedLanguageFlag = './assets/images/flags/en.png';
    }
    if (language === 'it') {
      this.languageService.setLanguage(2);
      this.currentLang = 'it';
      this.selectedLanguageText = 'languages.it';
      this.selectedLanguageFlag = './assets/images/flags/it.png';
    }
  }
}
