<!-- sponsor section Start-->
<hr class="mt-5 mb-0" style='max-width: 60%; background: #607d8b;'>
<section
    class="music sponsor bg-unset mb-5"
    id="sponsor"
    [ngStyle]="{ 'background-image': 'url(assets/images/music/sponser/bg.jpg)' }"
>
    <div class="container">
        <div class="row" style='justify-content: center;'>
            <!-- div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2 class="text-uppercase">{{"common.sponsors" | translate}}</h2>
                    </div>
                    <div class="sub-title">
                        <p>{{"common.nostri_sponsor" | translate}}</p>
                    </div>
                </div>
            </div -->
            <div class="col-lg-3 col-md-4 col-6" *ngFor="let sponsor of sponsor">
              <!--<a [routerLink]='[sponsor.url]' target='{{sponsor.linkTarget}}'>-->
              <a href='{{sponsor.url}}' [target]='sponsor.linkTarget'>
                <div class="sponsor-img">
                    <div class="text-center">
                        <div class="overlay-box fix-ratio-container">
                            <img [src]="getImageUrl(sponsor.photo)" alt="" class="img-fluid fix-ratio-img" />
                        </div>
                    </div>
                </div>
              </a>
            </div>
        </div>
    </div>
</section>
<!-- sponsor section end-->
