import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {dateTimeToScheduling, Scheduling, SchedulingDateTime} from '../../../shows/utils';
import {DateTime} from "luxon";

@Component({
    selector: 'app-date-time-bands',
    templateUrl: './date-time-bands.component.html',
    styleUrls: ['./date-time-bands.component.scss'],
})
export class DateTimeBandsComponent implements OnInit {
    @Input()
    schedulings: Scheduling[] = [];

    @Input()
    showLocation = false;

    @Input()
    customClass = '';

    showComponent = true;

    start: SchedulingDateTime | undefined;
    end: SchedulingDateTime | undefined;
    startLocation: string | undefined;

    locationName: string | undefined;
    address: string | undefined;

    constructor() {
        // Scheduling already sorted based on the current date
        this.showComponent = this.schedulings.length > 0;
    }

    ngOnInit(): void {
        this.initializeDates();
        const firstScheduling = this.schedulings[0];
        if (firstScheduling) {
            console.log(this.schedulings);
            this.showComponent = true;
            this.start = firstScheduling.startedAt;
            this.locationName = firstScheduling.locationName;
            this.end =
                this.schedulings.length > 1
                    ? this.schedulings[this.schedulings.length - 1].startedAt
                    : undefined;
            this.locationName = this.showLocation ? firstScheduling.locationName : undefined;
            this.address = this.showLocation ? firstScheduling.address : undefined;
        } else {
            console.log(this.schedulings);
            this.showComponent = false;
            this.start = undefined;
            this.end = undefined;
            this.locationName = undefined;
            this.address = undefined;
        }
    }

    initializeDates(): void {
      const todayAtMidnight = DateTime.local().startOf('day');
      this.schedulings = this.schedulings.filter((scheduling) =>  scheduling.startedAt.dateTime >= todayAtMidnight);
    }
}
