import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-404',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.scss'],
})
export class Four04Component implements OnInit {
  url = '';

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.url = this.router.url;
  }

}
