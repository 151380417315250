<a
  href="javascript:void(0)"
  type="button"
  aria-label="Close"
  (click)="close()"
  class="popup-close-button"
>
  {{ 'chiudi' | translate}}
</a>

<div class="modal-body" *ngIf="news !== undefined">
  <h3 class="popup-news-name">{{ news?.name }} </h3>
  <div class="title-spacer"></div>

  <div style="display: flow-root">
    <img
      src="{{ news?.photo ?  getImageUrl(news.photo!) : '/assets/images/no-photo.jpg' }}"
      alt="{{ news?.name }}"
      class="popup-photo"
    />


    <div
      class="static-page-html popup-description"
      *ngIf="news?.description"
      [innerHTML]="news?.description | safeHtml"
    ></div>
  </div>
</div>
