import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { defaultHeaders } from '../../utils';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-subscribe',
    templateUrl: './subscribe.component.html',
    styleUrls: ['./subscribe.component.scss'],
})
export class SubscribeComponent {
    email = '';
    doneMessage: string | undefined;
    disabled = false;

    constructor(private recaptchaV3Service: ReCaptchaV3Service, private http: HttpClient) {}

    submit(): void {
        this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
            this.http
                .post(
                    `${environment.tenant.api.baseUrl}/notifications/subscribe`,
                    {
                        email: this.email,
                    },
                    {
                        headers: {
                            recaptcha: token,
                            ...defaultHeaders,
                        },
                    },
                )
                .toPromise()
                .then((_) => {
                    this.doneMessage = 'Richiesta inviata correttamente.';
                })
                .catch((_) => {
                    this.email = '';
                    this.doneMessage = 'Errore';
                })
                .finally(() => {
                    setTimeout(() => {
                        this.email = '';
                        this.doneMessage = undefined;
                    }, 5000);
                });
        });
    }

    onChange(): void {
        this.disabled = this.email === '';
    }
}
