import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/navigation/menu/menu.component';
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './components/loader/loader.component';
import { SpacerComponent } from './components/spacer/spacer.component';
import { PageHeaderComponent } from '../layouts/page-header/page-header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DateTimeBandsComponent } from './components/date-time-bands/date-time-bands.component';
import { TranslateModule } from '@ngx-translate/core';
import { FiscalCodeValidatorDirective } from './custom-validators/fiscalCodeValidatorDirective';
import { ProvinceCodeValidatorDirective } from './custom-validators/provinceCodeValidatorDirective';
import { ZipCodeValidatorDirective } from './custom-validators/zipCodeValidatorDirective';
import { PhoneNumberValidatorDirective } from './custom-validators/phoneNumberValidatorDirective';

@NgModule({
    declarations: [
        MenuComponent,
        TapToTopComponent,
        LoaderComponent,
        SpacerComponent,
        PageHeaderComponent,
        FooterComponent,
        DateTimeBandsComponent,
        FiscalCodeValidatorDirective,
        ProvinceCodeValidatorDirective,
        ZipCodeValidatorDirective,
        PhoneNumberValidatorDirective,
    ],
    exports: [
        MenuComponent,
        TapToTopComponent,
        LoaderComponent,
        SpacerComponent,
        PageHeaderComponent,
        FooterComponent,
        DateTimeBandsComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        ToastrModule.forRoot(),
        TranslateModule.forChild({
            extend: true,
        }),
    ],
})
export class SharedModule {}
