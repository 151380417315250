/**
 * Shared types need to live in their own file due to replacement.
 */
export type Environment = {
    readonly type: 'production' | 'testing' | 'development';
    readonly title: string;
    readonly tenant: {
        readonly api: {
            readonly baseUrl: string;
            readonly assetsUrl: string;
            readonly imagesUrl: string;
            readonly imagesUrlOg: string;
            readonly pageSize: number;
        };
        readonly routes: Routes;
    };
};

type Routes = {
    shows: string;
    workshops: string;
    productions: string;
    projects: string;
    argine: string;
    contacts: string;
    ticketing: string;
    theatre: string;
    history: string;
    team: string;
    organization: string;
    places: string;
    prizes: string;
    news: string;
    archive: string;
};

export const isBrowser = () => {
    try {
        return typeof window !== 'undefined';
    } catch {
        return false;
    }
};

export type RootMenuTypes = keyof Routes;
