import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

export class CustomValidators {

  static fiscalCode(control: AbstractControl): any | null {
    return CustomValidators.fiscalCodeHandler(control.value)(control);
  }

  static fiscalCodeHandler(fiscalCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value || control.value.length === 0) {
        return null;
      }

      if (!/^[0-9A-Z]{16}$/.test(control.value)) {
        return { wrongFormat: true };
      }

      let s = 0;
      const evenMap = 'BAFHJNPRTVCESULDGIMOQKWZYX';
      for (let i = 0; i < 15; i++) {
        const c = control.value[i];
        let n = 0;
        if ('0' <= c && c <= '9') {
          n = c.charCodeAt(0) - '0'.charCodeAt(0);
        } else {
          n = c.charCodeAt(0) - 'A'.charCodeAt(0);
        }
        // tslint:disable-next-line:no-bitwise
        if ((i & 1) === 0) {
          n = evenMap.charCodeAt(n) - 'A'.charCodeAt(0);
        }
        s += n;
      }

      if ((s % 26) + 'A'.charCodeAt(0) === control.value.charCodeAt(15)) {
        return null;
      } else {
        return { wrongFormat: true };
      }
    }
    /*/^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/
        .test(control.value) ? null : { wrongFormat: true };*/
  }

  static provinceCode(control: AbstractControl): any | null {
    return CustomValidators.provinceCodeHandler(control.value)(control);
  }

  static provinceCodeHandler(provinceCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      /^([A-Z])([A-Z])$/
        .test(control.value) ? null : { wrongFormat: true };
  }

  static zipCode(control: AbstractControl): any | null {
    return CustomValidators.zipCodeHandler(control.value)(control);
  }

  static zipCodeHandler(zipCode: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      /([\d]{5})/
        .test(control.value) ? null : { wrongFormat: true };
  }

  static phoneNumber(control: AbstractControl): any | null {
    return CustomValidators.phoneNumberHandler(control.value)(control);
  }

  static phoneNumberHandler(phoneNumber: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null =>
      /*/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/'*/
        /^(\+\d{1,2}\s?)?\d{7,10}/
        .test(control.value) ? null : { wrongFormat: true };
  }

  /*static fileSize(control: AbstractControl, maxMBSize: number): any | null {
    return CustomValidators.fileSizeHandler(control.value, maxMBSize)(control);
  }*/

  static fileSize(files: FileList, maxMBSize: number): ValidatorFn {
    return ((control: AbstractControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const path = file.toString().replace(/^.*[\\\/]/, '');
        const fileSize = files.item(0)!.size;
        const fileSizeInMB = Math.floor(fileSize / (1024 * 1024));
        if (fileSizeInMB >= maxMBSize) {
          return {
            fileSizeValidator: true
          };
        } else {
          return null;
        }
      }
      return null;
    });
  }
}
