import { Component } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as O from 'fp-ts/Option';

import { environment } from '../../../environments/environment';
import { defaultHeaders } from '../../utils';
import { LanguageService } from '../../shared/service/language.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-write-us',
  templateUrl: './write-us.component.html',
  styleUrls: ['./write-us.component.scss'],
})
export class WriteUsComponent {
  doneMessage: string | undefined;
  language = '';
  email = '';
  message = '';
  name = '';

  constructor(
    private recaptchaV3Service: ReCaptchaV3Service,
    languageService: LanguageService,
    private http: HttpClient,
    private router: Router,
  ) {
    languageService.language$.subscribe((nextLanguage) => {
      const currentLanguage = O.toUndefined(nextLanguage);
      if (!!currentLanguage) {
        this.language = currentLanguage.code;
      }
    });
  }

  submit(): void {
    this.recaptchaV3Service.execute('importantAction').subscribe((token) => {
      this.http
        .post(
          `${environment.tenant.api.baseUrl}/notifications/mail-form`,
          {
            url: this.router.url,
            language: this.language,
            fullName: this.name,
            clientEmail: this.email,
            message: this.message,
          },
          {
            headers: {
              recaptcha: token,
              ...defaultHeaders,
            },
          },
        )
        .toPromise()
        .then((_) => {
          this.doneMessage = 'Richiesta inviata correttamente.';
        })
        .catch((_) => {
          this.email = '';
          this.name = '';
          this.message = '';
          this.doneMessage = 'Errore';
        })
        .finally(() => {
          setTimeout(() => {
            this.email = '';
            this.name = '';
            this.message = '';
            this.doneMessage = undefined;
          }, 5000);
        });
    });
  }
}
