import { Component, Input, OnInit } from '@angular/core';
import { Author} from '../../../productions/components/production/production.component';
import { environment } from '../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-author-popup',
  templateUrl: './author-popup.component.html',
  styleUrls: ['../popup.component.scss'],
})
export class AuthorPopupComponent implements OnInit {
  @Input()
  author: Author | undefined;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit(): void {}

  getImageUrl(src: string): string {
    return `${environment.tenant.api.baseUrl}/images/${src}`;
  }

  close(): void {
    this.ngbActiveModal.close();
  }
}
