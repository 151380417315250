import {
  AbstractControl, NG_VALIDATORS, Validator, ValidatorFn
} from '@angular/forms';
import {Directive} from '@angular/core';
import {CustomValidators} from './customValidators';

@Directive({
  selector: '[provinceCode-validator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: ProvinceCodeValidatorDirective,
    multi: true
  }]
})
export class ProvinceCodeValidatorDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } | null {
    return CustomValidators.provinceCode(control);
  }
}
