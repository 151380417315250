<a
  href="javascript:void(0)"
  type="button"
  aria-label="Close"
  (click)="close()"
  class="popup-close-button"
>
  {{ 'chiudi' | translate }}
</a>

<div class="modal-body" *ngIf="author !== undefined">
  <h3 class="popup-author-name">{{ author?.firstName }} {{ author?.lastName }}</h3>
  <div class="title-spacer"></div>

  <div>
    <img
      src="{{ author?.photo ?  getImageUrl(author.photo!) : '/assets/images/no-photo.jpg' }}"
      alt="{{ author?.firstName }} {{ author?.lastName }}"
      class="popup-photo"
    />

    <div
      class="static-page-html popup-description"
      *ngIf="author?.description"
      [innerHTML]="author?.description | safeHtml"
    ></div>
  </div>
</div>
