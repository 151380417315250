import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../shared/service/language.service';
import * as O from 'fp-ts/Option';
import { environment } from '../../../environments/environment';
import { AuthorPopupComponent } from '../popups/author-popup/author-popup.component';
import { Author } from '../../productions/components/production/production.component';
import { HttpClient } from '@angular/common/http';
import { PaginatedResponse } from '../common-types';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';


type Curriculum = {
  id: number;
  languageId: number;
  lastName: string;
  firstName: string;
  photo: string;
  description: string;
};

const { baseUrl } = environment.tenant.api;

@Component({
  selector: 'app-curricola',
  templateUrl: './curricola.component.html',
  styleUrls: ['./curricola.component.scss']
})
export class CurricolaComponent implements OnInit {

  curricola: Curriculum[] = [];
  currentPage = 1;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private languageService: LanguageService,
    private http: HttpClient,
    private translateService: TranslateService,
  ) {
    this.languageService.language$.subscribe((nextLanguage) => {
      const currentLanguage = O.toUndefined(nextLanguage);
      if (currentLanguage !== undefined) {
        this.initialize(currentLanguage.id).catch((e) => {
          // TODO: 500
          console.error(e);
        });
      }
    });
  }

  private async initialize(currentLanguageId: number): Promise<void> {
    const { data, page } = await this.fetchCurricula(this.http, currentLanguageId);
    this.curricola = data;
    this.currentPage = page;
  }

  getImageUrl(src: string) {
    return `${environment.tenant.api.baseUrl}/images/${src}`;
  }

  async ngOnInit(): Promise<void> {
    this.translateService.onLangChange.subscribe((event) => {
      this.initialize(event.lang === 'it' ? 2 : 1);
    });
  }

  openAuthorPopup(author: Author) {
    const modal = this.modalService.open(AuthorPopupComponent, {
      ariaLabelledBy: 'modal-basic-title',
    });
    modal.componentInstance.author = author;
  }

  async fetchCurricula(
    client: HttpClient,
    languageId: number
  ): Promise<PaginatedResponse<Curriculum>> {
    const query = `?languageId=${languageId}&isVisible=true&limit=100&page=1`;
    const response = await client
      .get<PaginatedResponse<Curriculum>>(`${baseUrl}/public/persons${query}`)
      .toPromise();
    response.data = response.data.map((curriculum: Curriculum) => {
      return curriculum;
    });
    return response;
  }

}
